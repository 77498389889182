/* eslint-disable max-len */
import * as React from 'react'

type NavLogoProps = {
  href?: string
  height?: string | number
}

export const NavLogo = ({
  href,
  height,
}: NavLogoProps) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 2231.19 355.35'
      height={height}
    >
      <g fill='#55b3c3'>
        <path d='M1301.47,176.99c-9.61-2.21-18.85-4.85-27.56-9.15-20.94-10.35-31.51-27.77-34.32-50.46-3.79-30.58,10.04-58.18,36.87-73.45,9.6-5.46,20.05-8.29,30.98-8.41,25.14-.29,50.29-.55,75.41,0,37.99.83,68.36,31.59,69.25,69.49.26,10.98,0,21.97.18,32.96.05,3.13-.66,4.32-4.08,4.3-20.98-.15-41.96-.17-62.94,0-3.73.03-4.27-1.42-4.31-4.66-.13-11.97.92-23.98-.57-35.91-1.76-14.14-10.16-23.46-23.09-28.13-12.83-4.64-25-2.16-35.36,7-8.06,7.12-11.88,16.21-11.62,27.02.13,5.49.01,10.99.03,16.48.05,18.64,9.57,30.24,27.93,34.04q7.74,1.6,7.74,9.48c0,7.82-.16,15.65.08,23.47.1,3.17-1.05,4.53-4.04,4.91-4.65.58-9.08,1.94-13.34,3.91-11.97,5.54-18,15.19-18.25,28.05-.38,19.47-.39,38.96-.03,58.43.43,23.33,19.39,32.81,37.11,32.26,2.64-.08,5.31-.54,7.87-1.19,16.94-4.31,25.49-15.76,25.59-34.53.11-21.81.13-43.62-.06-65.42-.04-4.18,1.18-5.33,5.33-5.29,21.64.2,43.29.14,64.93.07,21-.06,35.86-14.92,35.93-35.81.03-9.99.12-19.98-.06-29.96-.06-3.3.62-4.76,4.34-4.66,9.15.25,18.31.09,27.47.08,2.07,0,4.15-.15,4.05,2.91-.57,17.41,1.56,34.93-2.54,52.2-6.3,26.53-33.75,51.78-63.82,51.18-3.58-.07-4.37,1.15-4.43,4.54-.21,13.29,1.02,26.62-.86,39.88-4.72,33.33-37.58,62.07-71.18,62.15-22.98.05-45.95.07-68.93,0-18.68-.06-34.82-6.36-47.88-19.98-11.49-11.99-20.38-25.41-23.45-42.08-.06-.33-.21-.65-.21-.97-.23-18.95-2.83-38.2,1.15-56.75,5.16-24.05,17.5-43.81,41.91-53.37,6.01-2.35,12.4-3.32,18.76-4.59Z' />
      </g>
      <g fill='#010101'>
        <path d='M2159.8,326.46c-10.12,23.97-16.65,28.32-41.99,28.2-12.15-.06-24.31.64-36.44-.51-36.51-3.48-65.26-36.9-63.2-73.45,2.22-39.32,32.88-68.31,72.36-68.38,21.65-.03,43.29-.09,64.94.07,3.41.03,4.53-.7,4.43-4.31-.27-10.15-.02-20.31-.11-30.46-.18-20.05-15.48-35.3-35.5-35.42-11.32-.07-22.65-.04-33.97,0-20.65.06-32.81,10.48-35.43,30.85-.5,3.87-1.65,4.93-5.44,4.82-9.15-.28-18.31-.08-27.47-.1-1.79,0-3.89.42-3.47-2.64,2.86-21.24,8.8-41.05,26.23-55.32,11.41-9.34,24.99-13.22,39.4-13.43,26.64-.39,53.29-.57,79.92,0,34.69.74,67.23,32.17,67.11,71.01-.18,57.6-.07,115.2.02,172.8,0,3.4-.83,4.61-4.45,4.58-20.81-.16-41.63-.15-62.44-.01-3.46.02-4.69-.95-4.58-4.52.24-7.54.07-15.1.07-23.78ZM2159.8,269.86c0-5.98-.14-11.97.06-17.95.09-2.85-.81-3.71-3.65-3.64-7.31.18-14.63.04-21.94.07-6.3.02-12.65-.43-18.84,1.22-17.61,4.71-28.61,21.99-25.63,40.19,2.75,16.79,19.5,30.01,36.55,28.85,18.92-1.29,33.08-15.78,33.43-34.29.09-4.82.01-9.64.02-14.46Z' />
        <path d='M1097.53,325.97c-1.72,4.52-2.7,7.85-4.2,10.92-4.9,10-12.91,16.37-24.05,17.06-19.25,1.18-38.67,2.54-57.75-1.08-33.7-6.38-58.77-40.86-55.31-75,3.91-38.52,33.74-65.51,72.5-65.55,21.15-.02,42.3-.11,63.44.09,4.11.04,5.73-.56,5.52-5.24-.45-9.8-.13-19.64-.14-29.46,0-17.91-12.6-33.37-30.36-35.03-15.38-1.44-31.01-1.49-46.39.08-16.03,1.64-25.58,12.32-27.93,29.74-.63,4.67-2.41,5.51-6.55,5.37-8.65-.28-17.32-.2-25.97-.03-3.15.06-4.43-.43-3.89-4.1,1.68-11.42,4.35-22.52,9.5-32.89,11.37-22.87,30.68-33.71,55.45-34.39,26.95-.74,53.96-.72,80.92-.05,34.03.85,64.14,30.81,66.3,64.9.18,2.82.29,5.65.29,8.48.02,56.44-.05,112.88.13,169.32.01,4.72-1.36,5.74-5.83,5.69-20.15-.24-40.3-.28-60.44.03-4.62.07-5.51-1.33-5.31-5.54.35-7.31.1-14.64.1-23.32ZM1097.53,271.16c0-7.39-.09-13.55.04-19.71.05-2.32-.55-3.21-3.04-3.17-11.16.14-22.33-.3-33.47.18-18.75.81-33.09,15.32-33.76,33.52-.68,18.35,12.34,33.96,30.22,36.23,18.75,2.38,35.52-9.15,39.43-27.36,1.48-6.89.18-13.95.57-19.69Z' />
        <path d='M390.06,248.33c-21.81,0-43.62.17-65.42-.13-4.82-.07-5.67,1.5-5.53,5.81.3,9.48.08,18.97.1,28.46.04,19.8,13.58,34.95,33.25,35.97,13.77.72,27.65.69,41.41-.11,20.1-1.17,29.28-10.64,30.9-30.71.27-3.36,1.1-4.44,4.56-4.35,9.48.24,18.97.08,28.46.09,1.57,0,3.42-.55,2.98,2.38-2.33,15.59-6.12,30.65-15.48,43.72-12.22,17.05-29.63,24.43-49.86,24.89-28.44.64-56.95,1.58-85.34-.45-31.86-2.28-61.02-32.48-61.66-64.36-.78-39.26-.81-78.56,0-117.82.69-34.07,32.33-64.63,66.75-65.35,26.12-.54,52.27-.51,78.4-.01,35.09.67,65.84,31.22,67.19,67.17.9,23.93.19,47.92.41,71.89.03,3.74-2.17,2.92-4.18,2.92-22.31.01-44.61,0-66.92,0ZM354.36,212.31c10.65,0,21.29-.05,31.94.04,2.42.02,3.59-.41,3.55-3.24-.16-10.98.31-21.97-.19-32.93-.9-19.77-16.89-34.43-36.43-33.98-18.89.44-33.95,16.29-34.03,35.86-.04,10.15.11,20.29-.08,30.44-.06,3.02.63,3.95,3.8,3.88,10.48-.22,20.96-.08,31.44-.08Z' />
        <path d='M672.87,230.2c0-39.61.1-79.23-.13-118.84-.03-4.63,1.37-5.28,5.5-5.24,20.31.21,40.62.2,60.93,0,3.78-.04,4.93.78,4.92,4.79-.14,67.41-.04,134.82-.22,202.23-.01,4.74,1.39,5.77,5.81,5.62,10.14-.35,20.29-.09,30.43-.26,19.23-.32,34.42-15.51,34.46-34.8.13-57.42.14-114.85-.02-172.27-.01-4.46,1.12-5.38,5.42-5.33,20.47.25,40.95.19,61.43.03,3.32-.03,4.48.54,4.46,4.22-.15,41.94.08,83.89-.15,125.83-.33,60.71-50.23,113.99-111,117.81-32.9,2.07-65.9.32-98.86.75-4.19.06-2.93-2.71-2.93-4.71-.05-37.95-.04-75.9-.04-113.85v-5.99Z' />
        <path d='M1593.1,195.12c0-28.63-.07-57.25.02-85.88.1-33.04,19.42-60.74,48.87-70.37,7.67-2.51,15.59-3.52,23.58-3.54,24.3-.06,48.61-.15,72.9.22,15.37.23,30.12,3.4,42.66,13.05,14.78,11.37,21.08,27.38,23.73,45.13,1.93,12.94,1.74,12.97-11.38,12.97-6.66,0-13.32-.06-19.98.03-2.36.03-3.57-.5-3.77-3.24-.22-2.95-1.06-5.89-1.97-8.73-4.26-13.26-12.88-21.73-27.14-22.81-14.94-1.13-30-1.39-44.88-.02-18.25,1.69-31.13,16.08-31.2,34.3-.22,59.25-.24,118.5.01,177.75.08,18.77,13.88,33.27,32.75,34.4,13.6.81,27.31.72,40.92,0,16.86-.89,26.69-9.88,30.57-26.37.42-1.78.94-3.58,1-5.38.08-2.3.97-3.3,3.29-3.29,10.15.04,20.31.05,30.46,0,2.02-.01,2.39.85,2.49,2.7,1.89,35.13-24.48,65.76-59.41,67.87-29.74,1.8-59.62,2.05-89.32.06-35.38-2.37-62.94-31.87-63.89-67.47-.81-30.43-.17-60.91-.17-91.36-.05,0-.11,0-.16,0Z' />
        <path d='M.1,194.65C.1,143.23.15,91.8,0,40.37c-.01-4.04.77-5.25,5.05-5.19,20.47.26,40.95.26,61.42,0,4.27-.05,5.04,1.15,5.04,5.18-.13,90.71-.04,181.42-.24,272.12-.01,5.41,1.59,6.34,6.56,6.31,31.46-.23,62.92,0,94.38-.25,4.6-.04,5.44,1.31,5.28,5.5-.33,8.64-.36,17.32.01,25.96.18,4.22-1.4,4.78-5.07,4.78-55.77-.1-111.53-.12-167.3.03-4.33.01-5.14-1.38-5.13-5.36.12-51.6.08-103.19.08-154.79Z' />
        <path d='M602.25,230.09c0,39.77-.08,79.54.11,119.3.02,4.53-1.29,5.45-5.58,5.41-20.47-.23-40.94-.19-61.41-.02-3.42.03-4.45-.75-4.45-4.33.11-80.04.11-160.07,0-240.11,0-3.68,1.16-4.23,4.47-4.2,20.8.15,41.61.2,62.41-.03,3.86-.04,4.56,1.06,4.54,4.68-.14,39.77-.09,79.54-.09,119.3Z' />
        <path d='M1876.67,230.09c0-39.79.06-79.58-.1-119.37-.01-3.75.89-4.64,4.62-4.6,20.81.2,41.63.2,62.44,0,3.64-.03,4.24,1.08,4.24,4.44-.1,79.91-.1,159.82,0,239.73,0,3.34-.64,4.53-4.28,4.5-20.81-.19-41.63-.21-62.44.01-3.99.04-4.55-1.33-4.54-4.85.11-39.96.07-79.91.07-119.87Z' />
        <path d='M566.95,35.29c10.48,0,20.97.1,31.44-.07,2.91-.05,4.03.47,3.95,3.73-.25,9.64-.19,19.3-.03,28.94.05,2.81-.76,3.62-3.59,3.6-21.46-.11-42.93-.1-64.39,0-2.52.01-3.44-.53-3.39-3.25.17-9.81.17-19.63,0-29.44-.05-2.81.71-3.62,3.56-3.58,10.81.17,21.63.07,32.44.07Z' />
        <path d='M1947.81.09c-3.49,7.13-6.65,13.63-9.87,20.11-7.85,15.81-15.81,31.57-23.5,47.45-1.46,3.01-3.17,3.95-6.43,3.88-10.29-.22-20.59-.08-30.86-.08-.42-1.97.76-3.11,1.39-4.39,10.44-20.98,21-41.9,31.31-62.94,1.56-3.18,3.38-4.18,6.81-4.11,10.11.23,20.22.08,31.14.08Z' />
      </g>
    </svg>
  </a>
)

export default NavLogo
