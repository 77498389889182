import * as React from 'react'

import { Box } from '@mui/material'

import { AppNavElements } from 'shared/components'

import NavBar from '../components/nav_bar'

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({
  children,
}: LayoutProps) => (
  <React.Fragment>
    <NavBar>
      <AppNavElements configurationPages={['signature', 'bank']} />
    </NavBar>
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        width: '100%',
        bgcolor: 'action.hover',
        p: 3,
      })}
    >
      {children}
    </Box>
  </React.Fragment>
)

export default Layout
